import {
    PMDocumentPropertyEnum,
    PMChartBpcStatusEnum,
    PMChartStatusEnum,
    PMChartTypeEnum,
    PMProjectStatusEnum,
    PMChartVersionApprovalStatusEnum,
    PMTaskTypeEnum,
} from '@/ship/Enums/ProjectManagementEnums';
import { IIncludedValue } from '../Values/ITransformedValue';
import i18n from '@/plugins/VueI18n';
import User from './User';
import { Structure } from '@/ship/Models/ProjectManagement';

export interface IPMTableRow {
    id: number;
    name?: string;
    isOpen?: boolean;
}

export interface IPMCity extends IPMTableRow {
    projects: IPMProject[];
}

export interface IPMProject extends IPMTableRow {
    code: string | null;
    status: PMProjectStatusEnum;
    fileUrl: string | null;
    chartTypes: IPMChartType[];
}

export interface IPMChartType extends IPMTableRow {
    charts: IPMChart[];
}

export interface IPMChart extends IPMTableRow {
    status: PMChartStatusEnum;
    stage: string;
    managerId: number;
    designerId: number;
    startedAt: string;
    createdAt: string;
    updatedAt: string;
    calendarType: number;
    projectId: number;
    type: number;
    object?: PMDocumentPropertyEnum;
    versions?: IIncludedValue<IPMChartVersion[]>;
    baseVersion?: IIncludedValue<IPMChartVersion>;
}

export interface IPMChartBpc extends IPMTableRow {
    id: number;
    name: string;
    isBase: boolean;
    acceptedDate: string;
    status: PMChartBpcStatusEnum;
    chartId: number;
    chart?: IIncludedValue<IPMChart>;
    authorId: number;
    mainEngineerId: number;
    branchDirectorId: number;
    regionalHeadId: number;
    constructionDirectorDeputyId: number;
    generalDirectorDeputyId: number;
    createdAt: string;
    updatedAt: string;
    tasks?: IIncludedValue<IPMChartTask[]>;
}

export interface IPMChartDpc extends IPMTableRow {
    acceptedDate: string | null;
    authorId: number;
    branchDirectorId: number;
    chart?: IIncludedValue<IPMChart>;
    chartId: number;
    constructionDirectorDeputyId: number;
    createdAt: string;
    endDate: string;
    id: number;
    generalDirectorDeputyId: number;
    mainEngineerId: number;
    regionalHeadId: number;
    startDate: string;
    status: PMChartBpcStatusEnum;
    tasks?: IIncludedValue<IPMChartTask[]>;
    updatedAt: string;
}

export interface IPMChartDpcExec extends IPMTableRow {
    acceptedDate: string | null;
    authorId: number;
    branchDirectorId: number;
    chart?: IIncludedValue<IPMChart>;
    chartId: number;
    constructionDirectorDeputyId: number;
    createdAt: string;
    detailedScheduleId: number;
    id: number;
    generalDirectorDeputyId: number;
    mainEngineerId: number;
    regionalHeadId: number;
    status: PMChartBpcStatusEnum;
    tasks?: IIncludedValue<IPMChartDpcExecTask[]>;
    updatedAt: string;
}

export interface IPMChartWeeklyReport extends IPMTableRow {
    acceptedDate: string | null;
    authorId: number;
    branchDirectorId: number;
    chart?: IIncludedValue<IPMChart>;
    chartId: number;
    constructionDirectorDeputyId: number;
    createdAt: string;
    detailedScheduleId: number;
    id: number;
    generalDirectorDeputyId: number;
    mainEngineerId: number;
    regionalHeadId: number;
    status: PMChartBpcStatusEnum;
    tasks?: IIncludedValue<IPMChartDpcExecTask[]>;
    updatedAt: string;
}

export interface IPMChartRow extends IPMChart {
    selected: boolean;
}

export interface IPMChartDpcExecRow extends IPMChartDpcExec {
    selected: boolean;
}

export interface IPMChartWeeklyReportRow extends IPMChartDpcExec {
    selected: boolean;
}

export interface IPMChartDpcRow extends IPMChartDpc {
    selected: boolean;
}

export interface IPMChartBpcRow extends IPMChartBpc {
    selected: boolean;
}

export interface IPMChartTask {
    id: number;
    isOverdue: boolean;
    name: string;
    type: number;
    code: string;
    completeness?: string;
    baseScheduleId?: number;
    detailedScheduleId?: number;
    parentTaskId: number | null;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
    childTasks?: IIncludedValue<IPMChartTask[]>;
}

export interface IPMChartDpcExecTask {
    childTasks?: IIncludedValue<IPMChartDpcExecTask[]>;
    code: string;
    completenessFact: number;
    completenessGeneral: number;
    completenessPlan: number;
    createdAt: string;
    description: string;
    detailedScheduleExecutionId: number;
    endDateFact: string;
    endDatePlan: string;
    id: number;
    isOverdue: boolean;
    name: string;
    parentTaskId: number | null;
    startDateFact: string;
    startDatePlan: string;
    type: number;
    updatedAt: string;
}

export interface IPMReportDeadlinesTask {
    chartVersionId: number;
    childTasks?: IIncludedValue<IPMReportDeadlinesTask[]>;
    code: string; // код СДР
    completenessFact: number; // Исполнение ДПГ
    completenessGeneral: number; // % гот
    completenessPredict: number; // % гот. (прогноз)
    createdAt: string;
    durationGeneral: number; // Длительность
    durationWorkingDays: number; // Длительность (рабочие дни)
    durationPredict: number; // Длительность (рабочие дни, прогноз)
    endDate: string; // Завершение
    endDateBaseSchedule: string; // Завершение по БП
    endDeviation: number; // Отклонение завершения
    executorId: number | null;
    id: number;
    isOverdue: boolean;
    name: string;
    parentTaskId: number | null;
    stage: string;
    startDate: string; // Начало
    startDateBaseSchedule: string; // Начало по БП
    startDeviation: number; // Отклонение начала
    type: PMTaskTypeEnum;
    updatedAt: string;
    weight: number;
}

export interface IPMReport {
    id: number;
    name: string;
    status: PMChartStatusEnum;
    bpcName: string;
    isBase: boolean;
    projectId: number;
    authorId: number;
    createdAt: string;
    object: PMDocumentPropertyEnum;
}

export interface IPMProjectFilter {
    planned: number;
    approved: number;
    stopped: number;
    completed: number;
}

export interface IPMChartFilters {
    managers: number[];
    projects: number[];
    designers: number[];
    chartTypes: PMChartTypeEnum[];
    statuses: PMChartStatusEnum[];
    startFrom: string;
    startTo: string;
}

export interface IPMApprovalUsers {
    mainEngineerId: number;
    branchDirectorId: number;
    regionalHeadId: number;
    constructionDirectorDeputyId: number;
    generalDirectorDeputyId: number;
}

export const PMChartStatuses = new Map([
    [
        PMChartStatusEnum.planned,
        {
            code: PMChartStatusEnum[PMChartStatusEnum.planned],
            label: i18n.t('page.projectManagement.status.planned'),
            color: '#8729D1', // purple (changed)
            background: '#F7F5FF',
            border: '#F7F5FF',
        },
    ],
    [
        PMChartStatusEnum.approved,
        {
            code: PMChartStatusEnum[PMChartStatusEnum.approved],
            label: i18n.t('page.projectManagement.status.approved'),
            color: '#30C07C', // green
            background: '#F3FCF8',
            border: '#C6EED1',
        },
    ],
    [
        PMChartStatusEnum.stopped,
        {
            code: PMChartStatusEnum[PMChartStatusEnum.stopped],
            label: i18n.t('page.projectManagement.status.stopped'),
            color: '#EB4A14', // orange / red
            background: '#FCEFEF',
            border: '#FCEFEF',
        },
    ],
    [
        PMChartStatusEnum.completed,
        {
            code: PMChartStatusEnum[PMChartStatusEnum.completed],
            label: i18n.t('page.projectManagement.status.completed'),
            color: '#27B2B2', // sky blue
            background: '#F3FCFC',
            border: '#F3FCFC',
        },
    ],
]);

export const PMProjectStatuses = new Map([
    [
        PMProjectStatusEnum.decidingLaunch,
        {
            code: PMProjectStatusEnum[PMProjectStatusEnum.decidingLaunch],
            label: i18n.t('page.projectManagement.status.decidingLaunch'),
            color: '#8729D1', // purple (changed)
            background: '#F7F5FF',
            border: '#F7F5FF',
        },
    ],
    [
        PMProjectStatusEnum.designStarted,
        {
            code: PMProjectStatusEnum[PMProjectStatusEnum.designStarted],
            label: i18n.t('page.projectManagement.status.designStarted'),
            color: '#30C07C', //green
            background: '#E9F8ED',
            border: '#C6EED1',
        },
    ],
    [
        PMProjectStatusEnum.constructionUnderway,
        {
            code: PMProjectStatusEnum[PMProjectStatusEnum.constructionUnderway],
            label: i18n.t('page.projectManagement.status.constructionUnderway'),
            color: '#EB4A14', // orange / red
            background: '#FCEFEF',
            border: '#FCEFEF',
        },
    ],
    [
        PMProjectStatusEnum.workStopped,
        {
            code: PMProjectStatusEnum[PMProjectStatusEnum.workStopped],
            label: i18n.t('page.projectManagement.status.stopped'),
            color: '#EB4A14', // orange / red
            background: '#FCEFEF',
            border: '#FCEFEF',
        },
    ],
    [
        PMProjectStatusEnum.completed,
        {
            code: PMProjectStatusEnum[PMProjectStatusEnum.completed],
            label: i18n.t('page.projectManagement.status.completed'),
            color: '#27B2B2', // sky blue
            background: '#F3FCFC',
            border: '#F3FCFC',
        },
    ],
]);

export const PMChartBpcStatuses = new Map([
    [
        PMChartBpcStatusEnum.prepared,
        {
            code: PMChartBpcStatusEnum[PMChartBpcStatusEnum.prepared],
            label: i18n.t('page.projectManagement.status.prepared'),
            color: '#8729D1', // purple (changed)
            background: '#F7F5FF',
            border: '#F7F5FF',
        },
    ],
    [
        PMChartBpcStatusEnum.agreed,
        {
            code: PMChartBpcStatusEnum[PMChartBpcStatusEnum.agreed],
            label: i18n.t('page.projectManagement.status.agreed'),
            color: '#30C07C', // green
            background: '#E9F8ED',
            border: '#C6EED1',
        },
    ],
    [
        PMChartBpcStatusEnum.notAgreed,
        {
            code: PMChartBpcStatusEnum[PMChartBpcStatusEnum.notAgreed],
            label: i18n.t('page.projectManagement.status.notAgreed'),
            color: '#EB4A14', // orange / red
            background: '#FCEFEF',
            border: '#FCEFEF',
        },
    ],
    [
        PMChartBpcStatusEnum.onApproval,
        {
            code: PMChartBpcStatusEnum[PMChartBpcStatusEnum.onApproval],
            label: i18n.t('page.projectManagement.status.onApproval'),
            color: '#DA8E1B', // light orange
            background: '#FCF8EF',
            border: '#FCF8EF',
        },
    ],
]);

export interface IPMChartVersion {
    id: number;
    chartId: number;
    name: string;
    authorId: number;
    approvalStatus: PMChartVersionApprovalStatusEnum;
    isActualVersion: boolean;
    isBaseVersion: boolean;
    createdAt: string;
    updatedAt: string;
    description?: string;
    tasks?: IIncludedValue<Structure[]>;
}

export interface IPMChartVersionRow extends IPMChartVersion {
    selected: boolean;
}

export interface IPMChartHistory {
    id: number;
    authorId: number;
    updatedAt: string;
    createdAt: string;
    data: {
        status: {
            from: PMChartStatusEnum;
            to: PMChartStatusEnum;
        };
    };

    author?: IIncludedValue<User>;
    message?: string;
    submessage?: string;
}
